import React, { useState, useEffect } from "react";
import "../../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../../Icons/close.svg";
import { ReactComponent as AddStaff } from "../../Icons/staff.svg";
import CustomSelect from "../../Components/SelectOption/SelectOption";
import axios from "axios";
import { toast } from "react-toastify";

function StockModalEdit({
  isOpen,
  onClose,
  stockDetails,
  onUpdate,
  phone,
  showShopOption = true,
}) {
  const initialFormData = {
    barCode: "",
    vender: "",
    model: "",
    partName: "",
    color: "",
    price: 0,
    itemsInStock: 0,
    isAccessory: false,
  };
  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    if (window.location.pathname) {
      setFormData({
        ...formData,
        isAccessory:
          currentRoute === "/accessories" ? true : formData.isAccessory,
      });
    }

    // Add a listener for route changes
    window.addEventListener("popstate", handleRouteChange);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [currentRoute]);

  useEffect(() => {
    // Update formData with staffDetails when it's available
    if (stockDetails) {
      setFormData({
        barCode: stockDetails.barCode,
        vender: stockDetails.vender,
        model: stockDetails.model,
        partName: stockDetails.partName,
        color: stockDetails.color,
        price: stockDetails.price,
        itemsInStock: stockDetails.itemsInStock,
        isAccessory:
          currentRoute == "/accessories" ? true : stockDetails.isAccessory,
      });
    }
  }, [stockDetails]);
  console.log("qaiser", formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "barCode") {
      const barcodeValue = parseInt(value, 10);
      if (barcodeValue === 100000 || barcodeValue === 500000) {
        toast.error("This barcode value is not available.");
        return;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onUpdate({
      ...formData,
      isAccessory: currentRoute === "/accessories",
    });
  };

  useEffect(() => {
    if (phone) {
      setFormData({
        ...formData,
        isAccessory:
          currentRoute === "/accessories" ? true : formData.isAccessory,
      });
    }
  }, [phone]);

  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState();
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [clear, setClear] = useState(false);

  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };

  useEffect(() => {
    fetchShopsOptions();
  }, []);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error("Error while fetching shop options: ", error);
    }
  };

  const handleShopSelect = (selectedOption) => {
    // setSelectedShop(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      shopid: selectedOption,
    }));
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Edit Item</h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setFormData(initialFormData);
            }}
          />
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="barCode"
              placeholder="Barcode"
              value={formData.barCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="text"
              name="vender"
              placeholder="Vendor"
              value={formData.vender}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="model"
              placeholder="Model"
              value={formData.model}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="text"
              name="partName"
              placeholder="Name"
              value={formData.partName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="color"
              placeholder="Color"
              value={formData.color}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
              min={1}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="number"
              name="itemsInStock"
              placeholder="Items in stock"
              value={formData.itemsInStock}
              onChange={handleInputChange}
              min={1}
            />
          </div>
          {showShopOption && (
            <div className="input-boxer">
              <CustomSelect
                options={[{ label: "None", value: undefined }, ...shopsList]}
                isOpen={customSelectOpen2}
                setIsOpen={setCustomSelectOpen2}
                onClick={closeSelect3}
                clear={clear}
                name="Shops lists"
                icon={true}
                onSelect={handleShopSelect}
                placeholder="Select Shop"
              />
            </div>
          )}
        </div>

        <button className="continue-modal-button" onClick={handleSubmit}>
          <AddStaff />
          Edit Item
        </button>
      </div>
    </div>
  ) : null;
}

export default StockModalEdit;
