import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../Icons/close.svg';
import '../Styles/Modal.css'; // Import your CSS for modal styling
// import { ReactComponent as AddShop } from "../Icons/shop.svg"; // Import shop icon

function Modal({ isOpen, onClose, addShop }) {
  const [shopData, setShopData] = useState({
    name: '',
    email: '',
    password: '',
    mobile: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShopData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await addShop(shopData);
      onClose();
      setShopData({
        name: '',
        email: '',
        password: '',
        mobile: '',
      });
    } catch (error) {
      console.error('Error while adding shop: ', error);
    }
  };

  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='Upper-modal-row'>
          <h2>Add New Shop</h2>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='text'
              name='name'
              placeholder='Enter Name'
              value={shopData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <input
              type='email'
              name='email'
              placeholder='Enter Email'
              value={shopData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='password'
              name='password'
              placeholder='Enter Password'
              value={shopData.password}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <input
              type='text'
              name='mobile'
              placeholder='Enter Mobile Number'
              value={shopData.mobile}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button className='continue-modal-button' onClick={handleSubmit}>
          Add Shop
        </button>
      </div>
    </div>
  ) : null;
}

export default Modal;
