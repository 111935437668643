import React, { useState, useEffect } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import axios from "axios";
import CustomSelect from "../Components/SelectOption/SelectOption";

function Modal({ isOpen, onClose, addStaffMember }) {
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const initialFormData = {
    name: "",
    userName: "",
    shop: "",
  };

  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);
  const [shopsList, setShopsList] = useState([]);
  const [clear, setClear] = useState(false);
  const handleShopSelect = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      shop: selectedOption,
    }));
  };
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };

  useEffect(() => {
    fetchShopsOptions();
  }, []);
  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error("Error while fetching shop options: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Check for password match as user types
    if (name === "confirmPassword" && value !== formData.password) {
      setConfirmPasswordError("Password and Confirm Password do not match.");
    } else {
      setConfirmPasswordError(""); // Passwords match or the user changes the original password
    }
  };

  const handleSubmit = () => {
    // If passwords match, proceed to add the staff member
    const newFormData = {
      ...formData,
      name: formData.name,
      userName: formData.name,
    };
    addStaffMember(newFormData);

    onClose();
    setFormData(initialFormData);
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Add New Staff</h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setFormData(initialFormData);
            }}
          />
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <CustomSelect
              options={[{ label: "None", value: undefined }, ...shopsList]}
              isOpen={customSelectOpen2}
              setIsOpen={setCustomSelectOpen2}
              onClick={closeSelect3}
              clear={clear}
              name="shop"
              icon={true}
              onSelect={handleShopSelect}
              placeholder="Select Shop"
            />
          </div>
        </div>
        <button className="continue-modal-button" onClick={handleSubmit}>
          <AddStaff />
          Add Staff
        </button>
      </div>
    </div>
  ) : null;
}

export default Modal;
