import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
} from 'react-router-dom';
import './App.css';
import ProtectedRoute from './Components/ProtectedRoute';
import Home from './Pages/Home';
import HomePage from './Pages/HomePage';
import Login from './Pages/Login';
import { setAuthenticatedUser } from './Redux-store/userActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaidOut from './Pages/PaidOut';
import Stock from './Pages/Stock';
import Users from './Pages/Users';

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  /**This sets the redux user and token from localstorage use and token when user first time renders the app. */
  useEffect(() => {
    const localStorageToken = localStorage.getItem('token');
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    if (localStorageToken && localStorageUser) {
      dispatch(setAuthenticatedUser(localStorageUser, localStorageToken));
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Routes>
            <Route
              path='/login'
              element={token ? <Navigate to='/home' replace /> : <Login />}
            />

            <Route
              path='/*'
              element={token ? <HomePage /> : <Navigate to='/login' replace />}
            />

            <Route path='*' element={<Navigate to='/login' replace />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </>
  );
}

export default App;
