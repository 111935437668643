import React from 'react';
import { BiSearch } from 'react-icons/bi';
import '../Styles/Input.css';
function SearchInput({ placeholder, searchTerm, onChange, name = '' }) {
  return (
    <div className='search-input'>
      <input
        type='text'
        placeholder={placeholder}
        value={searchTerm}
        onChange={onChange}
        className='input-field'
        name={name}
      />
      <BiSearch className='search-icon' />
    </div>
  );
}

export default SearchInput;
