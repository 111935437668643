import React, { useState } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";
import Pin from "./Pin";
import { toast } from "react-toastify";
import axios from "axios";

function SellingModal({ isOpen, onClose, editSellingPriceDetails,fetchPurchasingData }) {
  const initialFormData = {
    sellingPrice: 0, // Add confirmPassword field
  };
  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Extract the necessary data from editSellingPriceDetails

    const queryBody = {
      id: editSellingPriceDetails._id,
      sellingPrice: formData.sellingPrice
    }

    try {
  
      // Make the PATCH request to update the selling price
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/purchasing`, queryBody);
  
      fetchPurchasingData();
      toast.success("Selling Price updated successfully!");
      onClose();
      setFormData(initialFormData);
  
      // Optionally, you can perform additional actions after a successful update
      // For example, closing a modal or updating state
      // ...
  
    } catch (error) {
      console.error('Error updating selling price:', error);
      toast.error("Error while updating selling price!")
    }
  
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal" style={{ maxWidth: "320px" }}>
        <div className="Upper-modal-row">
          <h2>Edit Selling</h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setFormData(initialFormData);
            }}
          />
        </div>
        <div className="row" style={{ flexDirection: "column", gap: "20px" }}>
          <div className="input-boxer" style={{ width: "100%" }}>
            <input
              type="number"
              name="sellingPrice"
              placeholder="Selling Price"
              value={formData.sellingPrice}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button className="continue-modal-button" onClick={handleSubmit}>
          <AddStaff />
          Save
        </button>
      </div>
    </div>
  ) : null;
}

export default SellingModal;
