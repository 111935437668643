import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../Icons/close.svg';
import { ReactComponent as AddStaff } from '../../Icons/staff.svg';
import '../../Styles/Modal.css';

function UnlockingDetModal({
  isOpen,
  onClose,
  selectedItemDetails,
  onUpdate,
  showShopOption = true,
  serviceList,
}) {
  const initialFormData = {
    imei: '',
    model: '',
    network: '',
    customerEmail: '',
    customerName: '',
    customerContact: '',
    status: '',
    charges: '',
    comment: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedItemDetails)
      setFormData({
        imei: selectedItemDetails.imei,
        model: selectedItemDetails.model,
        network: selectedItemDetails.network,
        customerEmail: selectedItemDetails.customerEmail,
        customerName: selectedItemDetails.customerName,
        customerContact: selectedItemDetails.customerContact,
        status: selectedItemDetails.status,
        charges: selectedItemDetails.charges,
        comment: selectedItemDetails.comment,
      });
    setError(null);
  }, [selectedItemDetails]);

  const handleInputChange = (e) =>
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = () => {
    if (formData.status === 'inProgress')
      return setError('Change the status to proceed updating data');
    onUpdate({ ...formData, _id: selectedItemDetails._id });
  };

  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='Upper-modal-row'>
          <h2>Edit Request</h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setFormData(initialFormData);
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <div className='row'>
            <FormControl fullWidth>
              <Select
                size='medium'
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: '#444',
                  '& *': {
                    border: 'none',
                  },
                  '& .Mui-disabled': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                    cursor: 'no-drop',
                  },
                  borderRadius: '10px',
                  boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset',
                  marginTop: '7px',
                }}
                name='service'
                value={selectedItemDetails.service._id}
                disabled={true}
              >
                {serviceList.map((el) => (
                  <MenuItem value={el.value}>{el.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='row'>
            <div className='input-boxer'>
              <input
                type='text'
                name='imei'
                placeholder='IMEI#'
                value={formData.imei}
                onChange={handleInputChange}
              />
            </div>
            <div className='input-boxer'>
              <input
                type='text'
                name='model'
                placeholder='Model'
                value={formData.model}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='input-boxer'>
              <input
                type='text'
                name='network'
                placeholder='Network'
                value={formData.network}
                onChange={handleInputChange}
              />
            </div>
            <div className='input-boxer'>
              <input
                type='text'
                name='customerName'
                placeholder='Customer Name'
                value={formData.customerName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='input-boxer'>
              <input
                type='email'
                name='customerEmail'
                placeholder='Customer Email'
                value={formData.customerEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className='input-boxer'>
              <input
                name='customerContact'
                placeholder='Customer Contact'
                value={formData.customerContact}
                onChange={handleInputChange}
                min={9}
              />
            </div>
          </div>
          <div className='row'>
            <div className='input-boxer'>
              <input
                name='charges'
                placeholder='Charges'
                value={`£${formData.charges}`}
                disabled={true}
                style={{
                  cursor: 'no-drop',
                }}
                // onChange={handleInputChange}
              />
            </div>
            <div className='input-boxer'>
              <FormControl fullWidth>
                <Select
                  size='medium'
                  sx={{
                    backgroundColor: '#f5f5f5',
                    color: '#444',
                    '& *': {
                      border: 'none',
                    },
                    borderRadius: '10px',
                    boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset',
                    '& .MuiSelect-select': {
                      paddingBlock: '23px',
                    },
                  }}
                  name='status'
                  value={formData.status}
                  onChange={handleInputChange}
                >
                  <MenuItem value={'inProgress'}>In Progress</MenuItem>
                  <MenuItem value={'success'}>Accept</MenuItem>
                  <MenuItem value={'rejected'}>Reject</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='row'>
            <div
              className='input-boxer'
              style={{
                width: '100%',
              }}
            >
              <textarea
                name='comment'
                value={formData.comment}
                onChange={handleInputChange}
                placeholder='Please dont use slashes or quotes while entering notes'
                rows={1}
                style={{
                  resize: 'none',
                }}
              />
            </div>
          </div>
          <div className='row'>
            <p style={{ color: 'red' }}>{error}</p>
          </div>
          <button className='continue-modal-button' onClick={handleSubmit}>
            <AddStaff />
            Edit Request
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default UnlockingDetModal;
