import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
export function InputBox({
  text,
  placeholder,
  type,
  value,
  onChange,
  icon,
  disabled,
}) {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <>
      <div className='input-box'>
        <label>{text}</label>
        {icon && <span className='icon'>{icon}</span>}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          disabled={disabled ? disabled : false}
        />
      </div>
    </>
  );
}
export function InputBoxSelect({ placeholder, text, type }) {
  return (
    <>
      <div className='input-box'>
        <label>{text}</label>
        <input type={type} placeholder={placeholder} />
        <span>
          <BiChevronDown />
        </span>
      </div>
    </>
  );
}
export function RadioButton({ text, name, selectedValue, onChange }) {
  return (
    <>
      <label className='custom-radio'>
        <input
          type='radio'
          name={name}
          value={text}
          checked={selectedValue === text}
          onChange={onChange}
        />
        <span className='checkmark'></span>
        <p>{text}</p>
      </label>
    </>
  );
}
// export function CheckBox({text}) {
//     return (
//       <>
//          <label class="custom-checkbox">
//               <input type="checkbox" />
//               <span class="checkmark"></span>
//               <p>{text}</p>
//             </label>
//       </>
//     );
//   }

export function CheckBox({ text, checked, onChange, onClick }) {
  return (
    <label class='custom-checkbox'>
      <input
        type='checkbox'
        onClick={onClick}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span class='checkmark'></span>
      <p>{text}</p>
    </label>
  );
}
export function CheckBoxAccessory({ text, checked, onChange, onClick }) {
  return (
    <label class='custom-checkbox'>
      <input
        type='checkbox'
        onClick={onClick}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span class='checkmark'></span>
      <p>{text}</p>
    </label>
  );
}

export function Check({ text }) {
  return (
    <>
      <label class='custom-checkbox'>
        <input type='checkbox' />
        <span class='checkmark'></span>
      </label>
    </>
  );
}
