import React from 'react'
import '../Styles/Input.css'

function Input({ type, placeholder, value, onChange }) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='login-input'
    />
  );
}



export default Input 