import React, { useState } from "react";
import PropTypes from "prop-types";
import "../Styles/SelectOption/SelecOption.css";
import { FaAngleDown } from "react-icons/fa";
import { ReactComponent as BlackIcon } from "../Icons/blackicon.svg";

import profile from "../Images/dp.png";

export function StaffSelect({ options, onSelect, name, icon, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [showInput, setShowInput] = useState(false); // State to control input field visibility
  const [searchQuery, setSearchQuery] = useState("");
  // const handleOptionClick = (option) => {
  //   setSelectedValue(option.label);
  //   setIsOpen(false);
  //   onSelect(option.value); // Call the onSelect callback with the selected value
  // };

  const handleButtonClick = () => {
    setIsOpen(true);
    console.log("button clicked");
    setShowInput(true); // Show input field when button is clicked
  };

  // const handleInputChange = (e) => {
  //   setSelectedValue(e.target.value);
  //   onSelect(e.target.value); // Update selected value and trigger onSelect with input value
  // };
  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value);
    // onClose()
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
    setSelectedValue(""); // Clear selected value when typing in the search input
  };

  return (
    <div
      className="custom-select"
      style={{
        padding: "0px 0px 0px 0px",
        maxWidth: "500px",
        background: "#f5f5f5",
        borderRadius: "10px",
      }}
    >
      {isOpen == false && (
        <button
          onClick={handleButtonClick}
          style={{
            background: "#f5f5f5",
            boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset",
            border: "1px solid #ccc",
          }}
        >
          {name === "Select Staff" && selectedValue === "" ? (
            <p> {selectedValue || name} </p>
          ) : (
            <p>
              {" "}
              <img src={profile} />
              {selectedValue || name}{" "}
              <BlackIcon
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("clicked");
                  setSelectedValue("");
                  onSelect("");
                  setIsOpen(false);
                }}
              />
            </p>
          )}
          <FaAngleDown />
        </button>
      )}
      {isOpen && (
        <div style={{ position: "relative", padding: "15px 34px 15px 33px" }}>
          {" "}
          <input
            type="text"
            className="selctor-input"
            value={searchQuery}
            onChange={handleInputChange}
            style={{ padding: "10px", width: "100%", boxSizing: "border-box" }}
            onBlur={() => setShowInput(false)} // Hide input field on blur
            placeholder="Enter value"
          />
          <span
            style={{
              position: "absolute",
              right: "7px",
              top: "16px",
              cursor: "pointer",
            }}
          >
            <BlackIcon
              onClick={(e) => {
                e.stopPropagation();
                console.log("clicked");
                setSelectedValue("");
                onSelect("");
                setIsOpen(false);
              }}
            />
          </span>
        </div>
      )}
      {isOpen && (
        <>
          <ul className="options" style={{ height: "300px" }}>
            {filteredOptions?.map((option) => (
              <li key={option.value} onClick={() => handleOptionClick(option)}>
                <p>
                  {" "}
                  <img src={profile} /> {option.label}{" "}
                </p>{" "}
                {option.label !== selectedValue && (
                  <span className="bordercircles">Select</span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
