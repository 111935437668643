import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../../Icons/dashboard.svg';
import { ReactComponent as DashboardBlackIcon } from '../../Icons/dashboardblack.svg';
import { ReactComponent as LogoutIcon } from '../../Icons/logout.svg';
import { ReactComponent as SettingsIcon } from '../../Icons/settings.svg';
import { ReactComponent as SettingsBlackIcon } from '../../Icons/settingsblack.svg';
import { ReactComponent as StockIcon } from '../../Icons/stock.svg';
import { ReactComponent as StockBlackIcon } from '../../Icons/stockblack.svg';
import { ReactComponent as UsersIcon } from '../../Icons/user.svg';
import { ReactComponent as UsersBlackIcon } from '../../Icons/userblack.svg';
import logo from '../../Images/login.png';
import { logoutUser } from '../../Redux-store/userActions';
import '../../Styles/Navbar/Navbar.css';
function Navbar() {
  const [navs, setNav] = useState('1');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == '/' || location.pathname == '/home') {
      setNav('1');
    } else if (location.pathname == '/users') {
      setNav('2');
      console.log(location.pathname);
    } else if (location.pathname == '/stocks') {
      setNav('3');
      console.log(location.pathname);
    } else if (location.pathname == '/settings') {
      setNav('4');
      console.log(location.pathname);
    } else if (location.pathname == '/paidout') {
      setNav('5');
      console.log(location.pathname);
    } else if (location.pathname == '/repairlisting') {
      setNav('6');
      console.log(location.pathname);
    } else if (location.pathname == '/selling') {
      setNav('7');
      console.log(location.pathname);
    } else if (location.pathname == '/accessories') {
      setNav('8');
      console.log(location.pathname);
    } else if (location.pathname == '/shop') {
      setNav('10');
      console.log(location.pathname);
    } else if (location.pathname == '/unlocking') {
      setNav('11');
      console.log(location.pathname);
    }
  }, [navs, location]);

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className='nav-bar'>
      <img src={logo} />
      <div className='nav-items'>
        <Link to='/home'>
          {navs == '1' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <DashboardIcon />
              </span>
              <p className='text-tag'>Dashboard</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <DashboardBlackIcon />
              </span>
              <p className='text-tag'>Dashboard</p>
            </div>
          )}
        </Link>
        <Link to={'/users'}>
          {' '}
          {navs == '2' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <UsersIcon />
              </span>
              <p className='text-tag'>Users</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <UsersBlackIcon />
              </span>
              <p className='text-tag'>Users</p>
            </div>
          )}
        </Link>
        <Link to={'/shop'}>
          {' '}
          {navs == '10' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <UsersIcon />
              </span>
              <p className='text-tag'>Shops</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <UsersBlackIcon />
              </span>
              <p className='text-tag'>Shops</p>
            </div>
          )}
        </Link>
        <Link to='/stocks'>
          {' '}
          {navs == '3' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <StockIcon />
              </span>
              <p className='text-tag'>Stock</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <StockBlackIcon />
              </span>
              <p className='text-tag'>Stock</p>
            </div>
          )}
        </Link>
        <Link to='/accessories'>
          {' '}
          {navs == '8' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <StockIcon />
              </span>
              <p className='text-tag'>Accessories</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <StockBlackIcon />
              </span>
              <p className='text-tag'>Accessories</p>
            </div>
          )}
        </Link>
        <Link to='/paidout'>
          {' '}
          {navs == '5' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <DashboardIcon />
              </span>
              <p className='text-tag'>Paidouts</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <DashboardBlackIcon />
              </span>
              <p className='text-tag'>Paidouts</p>
            </div>
          )}
        </Link>
        <Link to='/repairlisting'>
          {' '}
          {navs == '6' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <DashboardIcon />
              </span>
              <p className='text-tag'>Repair Listing</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <DashboardBlackIcon />
              </span>
              <p className='text-tag'>Repair Listing</p>
            </div>
          )}
        </Link>
        <Link to='/selling'>
          {' '}
          {navs == '7' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <SettingsIcon />
              </span>
              <p className='text-tag'>Sellings</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <SettingsBlackIcon />
              </span>
              <p className='text-tag'>Sellings</p>
            </div>
          )}
        </Link>
        <Link to='/unlocking'>
          {navs == '11' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <SettingsIcon />
              </span>
              <p className='text-tag'>Unlocking</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <SettingsBlackIcon />
              </span>
              <p className='text-tag'>Unlocking</p>
            </div>
          )}
        </Link>
      </div>
      <div className='more-nav-items'>
        <p className='more-heading'>More</p>

        <Link to='/settings'>
          {' '}
          {navs == '4' ? (
            <div className='nav-tag-active'>
              <span className='icon'>
                <SettingsIcon />
              </span>
              <p className='text-tag'>Settings</p>
            </div>
          ) : (
            <div className='nav-tag'>
              <span className='icon'>
                <SettingsBlackIcon />
              </span>
              <p className='text-tag'>Settings</p>
            </div>
          )}
        </Link>
      </div>
      <button className='logout' onClick={handleLogout}>
        <LogoutIcon />
        <p>Signout</p>
      </button>
    </div>
  );
}

export default Navbar;
