import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomSelect from '../../Components/SelectOption/SelectOption';
import { ReactComponent as CloseIcon } from '../../Icons/close.svg';
import { ReactComponent as AddStaff } from '../../Icons/staff.svg';
import { CheckBoxAccessory } from '../../Pages/InputBox';
import '../../Styles/Modal.css'; // Import your CSS for modal styling
function Modal({
  isOpen,
  onClose,
  addStockItem,
  showAccessoryCheckbox,
  showShopOption = true,
}) {
  const initialFormData = {
    barCode: '',
    vender: '',
    model: '',
    partName: '',
    color: '',
    price: 0,
    itemsInStock: 0,
    isAccessory: false,
  };
  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);
  const [shopsList, setShopsList] = useState([]);
  const [clear, setClear] = useState(false);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };
  const handleShopSelect = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      shop: selectedOption,
    }));
  };
  useEffect(() => {
    fetchShopsOptions();
    setFormData(initialFormData);
  }, []);
  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedShop, setSelectedShop] = useState();

  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    if (window.location.pathname) {
      setFormData({
        ...formData,
        isAccessory:
          currentRoute == '/accessories' ? true : formData.isAccessory,
      });
    }

    // Add a listener for route changes
    window.addEventListener('popstate', handleRouteChange);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);
  // const handleShopSelect = (selectedOption) => {
  //   setSelectedShop(selectedOption);
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'barCode') {
      const barcodeValue = parseInt(value, 10);
      if (barcodeValue === 100000000 || barcodeValue === 500000000) {
        toast.error('This barcode value is not available.');
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { color, barCode, ...formInfo } = formData;
    if ((+barCode).toString().length < 8 || (+barCode).toString().length > 8)
      return toast.error('Barcode is invalid (should be exact 8 digits)');
    console.log('formData', formData);
    addStockItem(!color ? { ...formInfo, barCode } : formData);
    isOpen ? setFormData(formData) : setFormData(initialFormData);
  };
  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='Upper-modal-row'>
          <h2>Add New Item</h2>
          <CloseIcon
            onClick={() => {
              setFormData(initialFormData);
              onClose();
            }}
          />
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='number'
              name='barCode'
              placeholder='Barcode'
              value={formData.barCode}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <input
              type='text'
              name='vender'
              placeholder='Brand'
              value={formData.vender}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='text'
              name='model'
              placeholder='Model'
              value={formData.model}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <input
              type='text'
              name='partName'
              placeholder='Part Name'
              value={formData.partName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='text'
              name='color'
              placeholder='Color'
              value={formData.color}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <input
              type='number'
              name='price'
              placeholder='Price'
              // value={formData.price}
              onChange={handleInputChange}
              // min={1}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <input
              type='text'
              name='itemsInStock'
              placeholder='Items in stock'
              // value={formData.itemsInStock}
              onChange={handleInputChange}
              // min={0}
            />
          </div>
          {showShopOption && (
            <div className='input-boxer'>
              <CustomSelect
                options={[{ label: 'None', value: undefined }, ...shopsList]}
                isOpen={customSelectOpen2}
                setIsOpen={setCustomSelectOpen2}
                onClick={closeSelect3}
                clear={clear}
                name='shop'
                placeholder='Shops List'
                icon={true}
                onSelect={handleShopSelect}
              />
            </div>
          )}
        </div>
        <div className='row'>
          <div style={{ width: '48%', display: 'flex' }}>
            {showAccessoryCheckbox ? (
              <CheckBoxAccessory
                text='Accessories'
                checked={
                  currentRoute == '/accessories' ? true : formData.isAccessory
                }
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    isAccessory: currentRoute == '/accessories' ? true : value,
                  })
                }
              />
            ) : null}
          </div>
        </div>

        {/* */}
        {/* <div className="columner">
          <div className="row" style={{ width: "320px" }}>
          <CheckBox
          text="Instock"
          isChecked={selectedOption === "Instock"}
          onChange={handleCheckBoxChange}
        />
     
          </div>
        </div> */}

        <button className='continue-modal-button' onClick={handleSubmit}>
          <AddStaff />
          Add Item
        </button>
      </div>
    </div>
  ) : null;
}

export default Modal;
