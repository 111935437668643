import React from "react";
import Chart from "react-apexcharts";

const CombinedSalesPieChart = ({ salesStats }) => {
  const cashSalesValue = parseFloat(
    (
      (salesStats?.saleStat?.cash || 0) - salesStats?.refundStat?.cash || 0
    ).toFixed(2)
  );

  const creditSalesValue = parseFloat(
    (
      (salesStats?.saleStat?.credit || 0) - salesStats?.refundStat?.credit || 0
    ).toFixed(2)
  );

  const data = {
    labels: ["Cash Sales", "Credit Sales"],
    series: [cashSalesValue, creditSalesValue],
  };

  const options = {
    labels: ["Cash Sales", "Credit Sales"],
    colors: ["rgb(4, 132, 172)", "rgb(255, 124, 92)"],
    legend: {
      show: true,
      position: "bottom",
    },
  };

  return (
    <div className="chart-container">
      <div className="center-text">
        <p>Sales Comparison</p>
      </div>
      <Chart
        options={options}
        series={data.series}
        type="donut"
        width="400"
        height="700"
      />
    </div>
  );
};

export default CombinedSalesPieChart;
