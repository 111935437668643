import React from 'react';
import '../../Styles/BoxItem/BoxItem.css';
import { BsArrowUp } from 'react-icons/bs';

function BoxItem({ text, number, icon, background }) {
  const backgroundStyle = {
    background: background,
  };

  let displayNumber = number; 

  if (typeof number === 'object') {
    displayNumber = number.inStock; 
  }
  
  return (
    <div className="orange-box" style={backgroundStyle}>
      <h3>{text}</h3>
      <div className="bpx">
        <h2>{displayNumber}</h2>{" "}
      </div>
    </div>
  );
}

export default BoxItem;
