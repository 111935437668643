import 'chart.js/auto';
import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';

const GraphChart = ({ data }) => {
  console.log('data', data);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const getMonthName = (monthNumber) => {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      // Ensure monthNumber is within valid range (1 to 12)
      const normalizedMonthNumber = Math.max(1, Math.min(12, monthNumber));

      // Get month name (array index is monthNumber - 1, since months array is zero-indexed)
      return months[normalizedMonthNumber - 1];
    };
    if (data && data.length > 0) {
      const labels = data.map((item) => {
        const monthNumber = item.month; // Numeric month value (1 for January, 2 for February, etc.)
        const monthName = getMonthName(monthNumber); // Get month name based on month number
        return monthName;
      });

      const datasetData = data.map((item) => item.totalSale);
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Sales',
            data: datasetData,
            backgroundColor: [
              'rgb(255, 124, 92)',
              'rgb(4, 132, 172)',
              'rgb(0, 170, 223)',
              'rgb(205, 80, 80)',
              // "rgba(255, 159, 64, 0.2)",
              // "rgba(75, 192, 192, 0.2)",
              // "rgba(54, 162, 235, 0.2)",
              // "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              'rgb(255, 159, 64)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
            ],
            borderWidth: 1,
          },
        ],
      };
      const options = {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setChartData(chartData);
      setChartOptions(options);
    }
  }, [data]);

  return (
    <div className='card'>
      <Chart type='bar' data={chartData} options={chartOptions} />
    </div>
  );
};
export default GraphChart;
