import "../../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../../Icons/close.svg";

function SellingDetailModal({ sellingData, close }) {
  const dataToShow = [
    { label: "ID", value: sellingData._id ?? "None" },
    { label: "Shop Name", value: sellingData.shopName ?? "None" },
    { label: "Bar Code", value: sellingData.barCode ?? "None" },
    { label: "Contact", value: sellingData.contact ?? "None" },
    { label: "Invoice", value: sellingData.invoice ?? "None" },
    {
      label: "Price",
      value: sellingData.price ? `£${sellingData.price}` : "None",
    },
    {
      label: "Selling Price",
      value: sellingData.sellingPrice ? `£${sellingData.sellingPrice}` : "None",
    },
    { label: "Defect", value: sellingData.defect ?? "None" },
    { label: "Model", value: sellingData.model ?? "None" },
    { label: "Make", value: sellingData.make ?? "None" },
    {
      label: "Date",
      value: sellingData.date
        ? new Date(sellingData.date).toLocaleString()
        : "None",
    },
    { label: "Customer Name", value: sellingData.customerName ?? "None" },
    { label: "Retailer", value: sellingData.retailer ?? "None" },
    { label: "Note", value: sellingData.note ?? "None" },
    { label: "IMEI", value: sellingData.imei ?? "None" },
    { label: "Reference", value: sellingData.reference ?? "None" },
    { label: "Accessories", value: sellingData.accessories ? "Yes" : "No" },
    { label: "Box", value: sellingData.box ? "Yes" : "No" },
    { label: "Charger", value: sellingData.changer ? "Yes" : "No" },
    {
      label: "Apple ID Removed",
      value: sellingData.appleIdRemoved ? "Yes" : "No",
    },
    { label: "Headphone", value: sellingData.headphone ? "Yes" : "No" },
    { label: "Other Text", value: sellingData.otherText ?? "None" },
  ];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Selling Details</h2>
          <CloseIcon onClick={close} />
        </div>
        <div style={{ height: "50vh", overflowY: "scroll" }}>
          {dataToShow.map((data) => (
            <div className="row">
              <div className="input-boxer">{data.label}</div>
              <div className="input-boxer">{data.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SellingDetailModal;
