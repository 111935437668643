import { Box, debounce, FormControl, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BoxItem from '../Components/BoxItem/BoxItem';
import SearchInput from '../Components/Search';
import CustomSelect from '../Components/SelectOption/SelectOption';
import Spinner from '../Components/Spinner';
import UnlockingDetModal from '../Components/UnlockingServiceModal/EditUnlockingDetModal';
import '../Styles/RepairSheet/RepairListing.css';

function Unlocking() {
  const [unlockingData, setUnlockingData] = useState([]);

  const [unlockStats, setUnlockStats] = useState({
    inProgress: 0,
    success: 0,
    rejected: 0,
  });
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [serviceList, setServiceList] = useState([]);

  const [searchItem, setSearchItem] = useState({
    make: '',
    network: '',
    model: '',
    imei: '',
    shop: '',
    customerName: '',
  });
  const [selectedShop, setSelectedShop] = useState();
  const [shopsList, setShopsList] = useState([]);
  const [shopListOpen, setShopListOpen] = useState();
  const [clear, setClear] = useState(false);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);

  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    setCustomSelectOpen2(false);
  };

  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };

  useEffect(() => {
    debouncedFetchPurchasedData();
  }, [searchItem, selectedShop]);

  const debouncedFetchPurchasedData = debounce(() => {
    fetchUnlockingData();
  }, 500);

  const fetchUnlockingData = async () => {
    try {
      setLoading(true);
      const searchTxt = filterValidFields(searchItem);
      let url = `/api/v1/unlocking?pageNumber=1&recordsPerPage=20000${
        Object.keys(searchTxt).length > 0 ? `&search=${searchTxt.search}` : ''
      }`;
      url += selectedShop ? `&shop=${selectedShop}` : '';
      // Join query parameters with '&'
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${url}`
      );
      setUnlockingData(response.data.data);
    } catch (er) {
      console.log('er', er);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/unlocking/status`
        );
        let stats = response.data.data.reduce((acc, item) => {
          acc[item._id] = item.count;
          return acc;
        }, {});
        setUnlockStats((st) => ({ ...st, ...stats }));
      } catch (er) {
        console.log('er', er);
      } finally {
        setFetching(false);
      }
    })();
    (async () => {
      try {
        setFetching(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/unlockService`
        );
        const serviceList = resp?.data?.data || [];
        if (serviceList.length)
          setServiceList(
            serviceList?.map?.((el) => ({
              label: el.service,
              value: el._id,
            }))
          );
      } catch (er) {
      } finally {
        setFetching(false);
      }
    })();
    fetchShopsOptions();
  }, []);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpens(true);
  };

  const closeModal = () => {
    setIsModalOpens(false);
    setSelectedItem(null);
  };

  const updateData = async (updatedData) => {
    try {
      const { _id, ...data } = updatedData;
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unlocking/${_id}`,
        {
          ...data,
        }
      );
      setUnlockingData((st) => ({
        ...st,
        list: st.list.map((el) =>
          el._id === _id
            ? { ...el, ...response.data.data, service: el.service }
            : el
        ),
      }));
      toast.success('Request updated successfully');
      closeModal();
    } catch (er) {
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (id) => async (e) => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unlocking/${id}`,
        {
          status: e.target.value,
        }
      );
      setUnlockingData((st) => ({
        ...st,
        list: st.list.map((el) =>
          el._id === id ? { ...el, status: e.target.value } : el
        ),
      }));
      toast.success('Request status updated successfully');
    } catch (er) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearcTxt = (e) => {
    const { name, value } = e.target;
    console.log('name,value', name, value);
    setSearchItem({
      customerName: name === 'customerName' ? value : '',
      makeModel: name === 'makeModel' ? value : '',
      imei: name === 'imei' ? value : '',
    });
  };

  const filterValidFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) acc['search'] = value;
      return acc;
    }, {});
  };

  return (
    <>
      {fetching ? (
        <Spinner />
      ) : (
        <div className='repair-listing'>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              gap: '10px',
              '& > div': {
                width: '100%',
                maxWidth: '250px',
              },
              overflowX: 'auto',
              marginBottom: '20px',
            }}
          >
            <BoxItem
              text='Total Requests'
              number={Object.values(unlockStats).reduce(
                (acc, count) => acc + count,
                0
              )}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE5353 100%)'
            />
            <BoxItem
              text='In Progress Requests'
              number={unlockStats.inProgress}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
            <BoxItem
              text='Accepted Requests'
              number={unlockStats.success || 0}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
            <BoxItem
              text='Rejected Requests'
              number={unlockStats.rejected}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
          </Box>
          <div className='search-row' style={{ marginBottom: '20px' }}>
            <SearchInput
              placeholder='Customer Name'
              searchTerm={searchItem.customerName}
              onChange={handleSearcTxt}
              name='customerName'
            />
            <SearchInput
              placeholder='Make & Model'
              searchTerm={searchItem.makeModel}
              onChange={handleSearcTxt}
              name='makeModel'
            />
            <SearchInput
              placeholder='IMEI'
              searchTerm={searchItem.imei}
              onChange={handleSearcTxt}
              name='imei'
            />
            <div style={{ minWidth: '200px' }}>
              <CustomSelect
                options={[{ label: 'None', value: undefined }, ...shopsList]}
                isOpen={shopListOpen}
                setIsOpen={setShopListOpen}
                onClick={closeSelect3}
                clear={clear}
                name='Shops lists'
                icon={true}
                onSelect={handleShopSelect}
                placeholder='Select Shop'
              />
            </div>
          </div>
          <div className='repair-listing-table'>
            {unlockingData?.count > 0 ? (
              <div
                style={{
                  width: ' 100%',
                  overflowY: 'scroll',
                  height: '100%',
                  marginBottom: '50px',
                }}
                // onScroll={handleTableScroll}
              >
                <table className='blueTable'>
                  <thead>
                    <tr>
                      <th>Invoice</th>
                      <th>Service</th>
                      <th>IMEI</th>
                      <th>Model</th>
                      <th>Network</th>
                      <th>Customer Name</th>
                      <th>Customer Email</th>
                      <th>Contact</th>
                      <th>Charges</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unlockingData?.list?.map((repair, index) => (
                      <tr key={repair._id}>
                        <td className='shrink'>{repair.invoice}</td>
                        <td className='shrink'>{repair?.service?.service}</td>
                        <td className='shrink'>{repair.imei}</td>
                        <td className='shrink'>{repair.model}</td>
                        <td className='shrink'>{repair.network}</td>
                        <td className='shrink'>{repair.customerName}</td>
                        <td className='shrink'>{repair.customerEmail}</td>
                        <td className='shrink'>{repair.customerContact}</td>
                        <td className='shrink'>£{repair.charges}</td>
                        <td className='shrink'>
                          {new Date(repair.createdAt).toLocaleDateString()}
                        </td>
                        <td className='shrink'>
                          <FormControl fullWidth>
                            <Select
                              size='small'
                              style={{
                                backgroundColor: 'rgb(245, 245, 245)',
                                color: 'rgb(68, 68, 68)',
                                border: '1px solid rgb(204, 204, 204)',
                                borderRadius: 10,
                              }}
                              value={repair.status}
                              onChange={handleStatusChange(repair._id)}
                              disabled={loading}
                            >
                              <MenuItem value={'inProgress'}>
                                In Progress
                              </MenuItem>
                              <MenuItem value={'success'}>Accept</MenuItem>
                              <MenuItem value={'rejected'}>Reject</MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td>
                          <button
                            className='submit'
                            onClick={() => {
                              openModal(repair);
                            }}
                            disabled={loading}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <table className='blueTable'>
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>Date</th>
                      <th>Invoice</th>
                      <th>IMEI</th>
                      <th>Model</th>
                      <th>Network</th>
                      <th>Customer Name</th>
                      <th>Contact</th>
                      <th>Charges</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                </table>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '18px',
                    fontWeight: 500,
                    marginBlock: '30px',
                  }}
                >
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <UnlockingDetModal
        isOpen={isModalOpens}
        onClose={closeModal}
        selectedItemDetails={selectedItem}
        onUpdate={updateData}
        serviceList={serviceList}
      />
    </>
  );
}

export default Unlocking;
