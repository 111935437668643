import React, { useState, useEffect } from "react";
import "../Styles/StockReport/Users.css";
import { ReactComponent as AdditonIcon } from "../Icons/addition.svg";
import BoxItem from "../Components/BoxItem/BoxItem";
import CustomSelect from "../Components/SelectOption/SelectOption";
import { MdEdit, MdDelete } from "react-icons/md";
import SearchInput from "../Components/Search";
import Modal from "../Components/Modal";
import EditStaffModal from "../Components/EditStaffModal";
import DeleteModal from "../Components/DeleteModal";
import axios from "axios";
import debounce from "lodash/debounce"; // Import debounce function

import { toast } from "react-toastify";
import Spinner from "../Components/Spinner";

function Users() {
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };

  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [totalStaff, setTotalStaff] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [deleteModal, setModalDelete] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [editableStaffData, setEditableStaffData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [staffStats, setStaffStats] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const startingIndex = (currentPage - 1) * itemsPerPage + 1;
  const [show, setShow] = useState(false);
  const [clear, setClear] = useState(false);

  const [date, setDate] = useState(null);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchTotalMembersCount();
  }, []);

  const [selectedShop, setSelectedShop] = useState();

  useEffect(() => {
    fetchStaffMembers();
  }, [refresh, searchTerm, currentPage, selectedShop]);

  const fetchStaffMembers = async () => {
    const pageNumber = currentPage;
    const recordsPerPage = itemsPerPage;
    try {
      const url = searchTerm
        ? `staffMember/search/${searchTerm}?`
        : `staffMember`;
      const shopFilteredUrl = selectedShop
        ? `${url}?shop=${selectedShop}`
        : `${url}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${shopFilteredUrl}`
      );
      setStaffMembers(response.data.data.staffMembers);
      setTotalStaff(response.data.data.count);
    } catch (error) {
      console.error("Error while fetching staff members: ", error);
    }
  };

  const debouncedFetchStaffMembers = debounce(fetchStaffMembers, 300); // Adjust the debounce delay as needed

  const addStaffMember = async (data) => {
    const { name, userName, password, shop } = data;
    const staffPayload = {
      name,
      userName,
      password,
      shop,
    };
    setRefresh(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember`,
        staffPayload
      );
      setRefresh(false); // This can be used to refresh the staff member list.
      fetchTotalMembersCount();
      closeModal();
      toast.success("User has been registered successfully");
    } catch (error) {
      console.error(`Error while adding staff member:`, error);
      toast.error(`Error while adding staff member:`, error);
    }
  };

  const updateStaffMember = async (staffId, data) => {
    const { name, userName, password, shop } = data;
    setRefresh(true);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/staffMember`, {
        name,
        userName,
        password,
        staffId,
        shop,
      });
      setRefresh(false); // This can be used to refresh the staff member list.
      closeModalEdit();
      toast.success("User details has been updated successfully");
    } catch (error) {
      console.error(
        `Error while updating staff member with ID ${staffId}:`,
        error
      );
      toast.error(
        `Error while updating staff member with ID ${staffId}:`,
        error
      );
    }
  };

  const deleteStaffMember = async (selectedStaffId) => {
    setRefresh(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/${selectedStaffId}`
      );
      setRefresh(false);
      setStaffMembers((prevStaffMembers) =>
        prevStaffMembers.filter((staff) => staff._id !== selectedStaffId)
      );
      setModalDelete(false);
      toast.success("User is deleted successfully");
    } catch (error) {
      console.error("Error while deleting staff member: ", error);
      toast.error("Error while deleting staff member: ", error);
    }
  };

  const fetchTotalMembersCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/count`
      );
      setStaffStats(response.data.data);
    } catch (error) {
      console.error("Error while fetching staff members count: ", error);
    }
  };

  function formatedDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModalEdit = (staffDetails) => {
    setEditableStaffData(staffDetails);
    setModalEdit(true);
  };

  const closeModalEdit = () => {
    setModalEdit(false);
  };
  const openModalDelete = (staffId) => {
    setModalDelete(true);
    setSelectedStaffId(staffId);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  const handleSearch = () => {
    debouncedFetchStaffMembers();
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [tableSpinner, setTableSpinner] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setTableSpinner(false);
    }, 4000);
  }, []);

  const [shopsList, setShopsList] = useState([]);

  useEffect(() => {
    fetchShopsOptions();
  }, []);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error("Error while fetching shop options: ", error);
    }
  };

  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="user-container">
          <div className="row-container">
            <BoxItem
              text="Staff Members"
              number={staffStats}
              icon={false}
              background="linear-gradient(180deg, #AD2626 0%, #EE5353 100%)"
            />
            <div
              className="button-box"
              onClick={() => {
                openModal();
              }}
            >
              <div className="icon">
                <AdditonIcon />
              </div>
              <h3> Add New Member</h3>
            </div>
          </div>
          <div className="hsls">
            <div className="search-row">
              <SearchInput
                placeholder="Search By Staff Name or Email"
                searchTerm={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div style={{ width: "30%", paddingTop: "0.25%" }}>
                <CustomSelect
                  options={[{ label: "None", value: undefined }, ...shopsList]}
                  isOpen={customSelectOpen2}
                  setIsOpen={setCustomSelectOpen2}
                  onClick={closeSelect3}
                  clear={clear}
                  name="Shops lists"
                  icon={true}
                  onSelect={handleShopSelect}
                  placeholder="Select Shop"
                />
              </div>
              <button className="search-button" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
          <div className="stock-table">
            {staffMembers && staffMembers.length > 0 ? (
              <>
                {tableSpinner ? (
                  <Spinner isLoading={tableSpinner} />
                ) : (
                  <>
                    <table class="blueTable">
                      <thead>
                        <tr>
                          <th className="short">#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Created On</th>
                          <th className="long">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffMembers?.map((staff, index) => (
                          <tr key={index}>
                            <td>{index + startingIndex}</td>
                            <td>{staff.name}</td>
                            <td>{staff.userName}</td>
                            {/* <td>12345678</td> */}
                            <td>{formatedDate(staff.createdAt)}</td>
                            <td>
                              <div className="navi">
                                <button
                                  className="next"
                                  onClick={() => {
                                    openModalEdit(staff);
                                  }}
                                >
                                  <MdEdit /> Edit
                                </button>
                                <button
                                  className="prev"
                                  onClick={() => {
                                    openModalDelete(staff._id);
                                  }}
                                >
                                  <MdDelete />
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <>
                <table class="blueTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Created On</th>
                      <th className="long">Actions</th>
                    </tr>
                  </thead>
                </table>
                <div className="no-data">
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>

          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            addStaffMember={(data) => addStaffMember(data)}
          />
          <EditStaffModal
            isOpens={modalEdit}
            onCloses={closeModalEdit}
            staffDetails={editableStaffData}
            onUpdate={(data) => updateStaffMember(editableStaffData._id, data)}
          />
          <DeleteModal
            isDelete={deleteModal}
            title={"Delete user?"}
            ondeleteClose={closeModalDelete}
            selectedRecordId={selectedStaffId}
            deleteRecord={deleteStaffMember}
          />
        </div>
      )}
    </>
  );
}

export default Users;
