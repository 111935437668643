import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RepairProgress } from '../Components/RepairProgress';
import SearchInput from '../Components/Search';
import CustomSelect from '../Components/SelectOption/SelectOption';
import Spinner from '../Components/Spinner';
import { updateRepairStatus } from '../Functions/updateRepairStatus.function';
import '../Styles/RepairSheet/RepairListing.css';

function RepairListing() {
  const [clear, setClear] = useState(false);

  const [repairList, setRepairList] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");

  const [searchItem, setSearchItem] = useState({
    barCode: '',
    customerName: '',
    makeModel: '',
    imei: '',
  });
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };
  const [selectedShop, setSelectedShop] = useState();

  const debouncedFetchRepairList = debounce(() => {
    fetchRepairList();
  }, 500);

  const [repairStats, setRepairStats] = useState({
    inProgress: 0,
    fixed: 0,
    unfixed: 0,
  });
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    setCustomSelectOpen2(false);
  };

  useEffect(() => {
    fetchRepairStats();
    fetchRepairList();
    fetchShopsOptions();
  }, [loading]);

  useEffect(() => {
    debouncedFetchRepairList();
  }, [searchItem, selectedShop]);

  const fetchRepairList = async () => {
    try {
      const { imei, ...searchedItems } = searchItem;
      const searchTxt = filterValidFields(searchedItems);
      let url = `/api/v1/repair?pageNumber=1&recordsPerPage=20000`;
      if (Object.keys(searchTxt).length > 0)
        url += `&search=${searchTxt.search}`;
      if (imei) url += `&imei=${imei}`;
      if (selectedShop) url += `shop=${selectedShop}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${url}`
      );
      const repairData = response.data.data;
      setRepairList(repairData);
      setTableSpinner(false);
    } catch (error) {
      console.error('Error while fetching repair entries: ', error);
    }
  };
  const [shopsList, setShopsList] = useState([]);
  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };
  const [shopListOpen, setShopListOpen] = useState();

  const fetchRepairStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/repair/stat`
      );
      const statsData = response.data.data[0];

      // Update the state with the fetched repair stats
      setRepairStats({
        inProgress: statsData.inProgress || 0,
        fixed: statsData.fixed || 0,
        unfixed: statsData.unfixed || 0,
      });
    } catch (error) {
      console.error('Error while fetching repair stats: ', error);
    }
  };

  const updateRepair = (id, status) => {
    const data = { status };
    updateRepairStatus(id, data)
      .then((res) => toast.success(res))
      .catch((e) =>
        toast.error(e.message || 'There was an error updating the repair')
      )
      .finally(() => {
        fetchRepairStats();
        fetchRepairList();
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [tableSpinner, setTableSpinner] = useState(true);

  const handleSearcTxt = (e) => {
    const { name, value } = e.target;
    console.log('name,value', name, value);
    setSearchItem({
      barCode: name === 'barCode' ? value : '',
      customerName: name === 'customerName' ? value : '',
      makeModel: name === 'makeModel' ? value : '',
      imei: name === 'imei' ? value : '',
    });
  };

  const filterValidFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) acc['search'] = value;
      return acc;
    }, {});
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='repair-listing'>
          <div
            className='search-row'
            style={{
              gap: '7px',
            }}
          >
            <SearchInput
              placeholder='Barcode'
              searchTerm={searchItem.barCode}
              onChange={handleSearcTxt}
              name='barCode'
            />
            <SearchInput
              placeholder='Customer Name'
              searchTerm={searchItem.customerName}
              onChange={handleSearcTxt}
              name='customerName'
            />
            <SearchInput
              placeholder='Make & Model'
              searchTerm={searchItem.makeModel}
              onChange={handleSearcTxt}
              name='makeModel'
            />
            <SearchInput
              placeholder='IMEI'
              searchTerm={searchItem.imei}
              onChange={handleSearcTxt}
              name='imei'
            />
            <div
              className='search-input'
              style={{ marginRight: '1%', width: '15%' }}
            >
              <CustomSelect
                options={[{ label: 'None', value: undefined }, ...shopsList]}
                isOpen={shopListOpen}
                setIsOpen={setShopListOpen}
                onClick={closeSelect3}
                clear={clear}
                name='Shops lists'
                icon={true}
                onSelect={handleShopSelect}
                placeholder='Select Shop'
              />
            </div>
            {/* <button className='search-button'>Search</button> */}
          </div>
          <div className='row'></div>
          <div className='repair-listing-table'>
            {tableSpinner ? (
              <Spinner />
            ) : (
              <>
                {' '}
                {repairList && repairList.length > 0 ? (
                  <>
                    {tableSpinner ? (
                      <Spinner />
                    ) : (
                      <table className='blueTable'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Barcode</th>
                            <th>Make & Model</th>
                            <th>IMEI</th>
                            <th>Customer Name</th>
                            <th>Contact</th>
                            <th>Faults</th>
                            <th style={{ width: '10%' }}>Status</th>
                            <th style={{ width: '10%' }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {repairList.map((repair, index) => (
                            <tr key={repair._id}>
                              <td>
                                {new Date(repair.date).toLocaleDateString()}
                              </td>
                              <td>{repair.barCode}</td>
                              <td>{repair.makeAndModel}</td>
                              <td>{repair.imei}</td>
                              <td>{repair.customerName}</td>
                              <td>{repair.contact}</td>
                              <td>{repair.faults}</td>
                              <td>
                                <RepairProgress
                                  selectedValue={repair.status}
                                  onChange={(s) => updateRepair(repair._id, s)}
                                />
                              </td>

                              <td className='disca'>
                                <Link
                                  to={`/buyer/repair-sheet/detail?repairId=${repair._id}`}
                                >
                                  <button className='submit'>
                                    View Details
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                ) : (
                  <>
                    <table className='blueTable'>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Deposit</th>
                          <th>Balance</th>
                          <th>Make & Model</th>
                          <th>Customer Name</th>
                          <th>Contact</th>
                          <th>Password</th>
                          <th>IMEI</th>
                          <th>Faults</th>
                          <th>Status</th>
                          <th>Spare Phone</th>
                          <th>Exp. Return</th>
                          <th>Notes</th>
                          <th style={{ width: '10%' }}>Actions</th>
                        </tr>
                      </thead>
                    </table>
                    <div className='no-data'>
                      <p>No data available</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default RepairListing;
