import React,{useState} from "react";
import "../Styles/Settings/Settings.css";
import EmailChangeModal from "../Components/EmailChangeModal";
import PasswordChangeModal from "../Components/PasswordChangeModal";
import RequestOTPModel from "../Components/RequestOTPModel";
function Settings() {
   const [show,setShow] = useState(false);
   const [shows,setShows] = useState(false);
  return (
    <div className="settings">
      <div className="row-setting">
        <p className="text">Change your account password.</p>
        <button className="button-settings " onClick={()=>{setShow(true)}}>Change</button>
        
      </div>
      <div className="row-setting">
        <p className="text">Change your Email.</p>
        <button className="button-settings" onClick={()=>{setShows(true)}}>Change</button>
       {shows && <RequestOTPModel isChangeEmail={true}  isOpen={shows} closeEmailModal={setShow} onClose={()=>{setShows(false)}}/>}
       {show && <RequestOTPModel isChangeEmail={false}  isOpen={show} closeEmailModal={setShow} onClose={()=>{setShow(false)}}/>}


       {/* {show && <PasswordChangeModal  isOpen={show} onClose={()=>{setShow(false)}}/>} */}
      </div>
    </div>
  );
}

export default Settings;
///
