// import * as React from 'react';
// import { usePinInput } from 'react-pin-input-hook';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// export default function Pin() {
//   const [values, setValues] = React.useState(Array(5).fill(''));
//   const { fields, isCompleted } = usePinInput({
//     values,
//     onChange: (newValues) => {
//       setValues(newValues);
//     },
//   });

//   console.log("isCompleted",isCompleted)
//   React.useEffect(() => {
//     if (isCompleted) {
//       const pin = values.join(''); // Join PIN values into a single string
//       sendPinToAPI(pin); // Call API with the completed PIN
//     }
//   }, [isCompleted, values]);

//   const sendPinToAPI = async (pin) => {
//     try {
//       const user = JSON.parse(localStorage.getItem('user'));
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/verifyPin`,
//         { otp: pin, email: user?.email }
//       );

//       console.log('PIN verified', response.data);
//       toast.success(response?.data?.msg);
//       // Additional logic after successful API call
//     } catch (error) {
//       console.error('Error verifying PIN:', error);
//       toast.error('Error verifying PIN');
//       // Handle API error
//     }
//   };

//   return (
//     <div className="pin-input">
//       {fields.map((propsField, index) => (
//         <input key={index} className="pin-input__field" {...propsField} />
//       ))}
//     </div>
//   );
// }
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, Route, Routes } from "react-router-dom";
import PasswordChangeModal from './PasswordChangeModal';
import { useDispatch } from "react-redux";
import { logoutUser } from "../Redux-store/userActions";
const Pin = ({ closeModal, closeEmailModal, isChangeEmail }) => {
  const [values, setValues] = useState(['', '', '', '', '']); // Initialize with 4 empty strings for each PIN digit
  const inputRefs = useRef([]);
  const [selectedModel, setSelectedModel] = useState(1)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Focus on the first input field when component mounts
    inputRefs.current[0]?.focus();
  }, []);
  const closeModale = () => {
    closeModal(false)
    closeEmailModal(false)
  }

  const handleChange = (index, newValue) => {
    if (newValue.match(/^\d*$/)) {
      // Allow only numeric input
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);

      // Move focus to the next input field if available
      if (newValue !== '' && index < values.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && values[index] === '' && index > 0) {
      // Move focus to the previous input field on backspace press if the current field is empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  const sendPinToAPI = async () => {
    try {
      const pin = values.join(''); // Join PIN values into a single string
      const user = JSON.parse(localStorage.getItem('user'));
      
      console.log("User", user)
      let requstData;
      if(isChangeEmail){
        const getNewEmail = JSON.parse(localStorage.getItem('newEmail'));
        console.log("getNewEmail",getNewEmail)
        requstData={otp:pin,newEmail:getNewEmail?.newEmail}
    }else{
        requstData={otp:pin,email:user?.email}
    }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/verifyOtp`,
        requstData
      );

      if(isChangeEmail){
        dispatch(logoutUser());
        toast.success("Your email has been reset successfully");
        setValues(['', '', '', '', '']);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("newEmail");
        navigate("/login");
      }else{
        console.log('PIN verified', response.data);
        setSelectedModel(2)
        toast.success("PIN verified");
        setValues(['', '', '', '', '']);
      }
      // Additional logic after successful API call
    } catch (error) {
      setValues(['', '', '', '', '']);
      console.error('Error verifying PIN:', error);
      toast.error('OTP Not Verified');
        closeModale()
      // Handle API error
    }
  };

  useEffect(() => {
    // Check PIN completion when values change
    const isCompleted = values.every(value => value !== '');
    if (isCompleted) {
      sendPinToAPI();
    }
  }, [values]);

  return (

    <>
      {selectedModel==1 ? <div className="pin-input">
        {values.map((value, index) => (
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            className="pin-input__field"
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div> : <PasswordChangeModal closeModale={closeModale} />}

    </>
  );



};

export default Pin;

