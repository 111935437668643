import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CustomSelect from '../Components/SelectOption/SelectOption';
import { ReactComponent as CloseIcon } from '../Icons/close.svg';
import { ReactComponent as AddStaff } from '../Icons/staff.svg';
import { CheckBox, CheckBoxAccessory } from '../Pages/InputBox';
import '../Styles/Modal.css'; // Import your CSS for modal styling
function AddSellingItemModal({ isOpen, onClose }) {
  const currentDate = new Date();
  const initialFormData = {
    date: `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`,
    invoice: Math.floor(100000 + Math.random() * 900000),
    contact: '',
    price: '',
    model: '',
    retailer: '',
    note: '',
    imei: '',
    accessories: false,
    box: false,
    charger: false,
    headphone: false,
    otherText: null,
    appleIdRemoved: false,
    shop: '',
    defect: '',
    other: false,
  };

  const { user } = useSelector((state) => state.user);

  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);
  const [shopsList, setShopsList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [clear, setClear] = useState(false);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };
  const handleShopSelect = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      shop: selectedOption,
    }));
  };
  useEffect(() => {
    fetchShopsOptions();
  }, []);
  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedShop, setSelectedShop] = useState();

  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    if (window.location.pathname) {
      setFormData({
        ...formData,
        isAccessory:
          currentRoute == '/accessories' ? true : formData.isAccessory,
      });
    }

    // Add a listener for route changes
    window.addEventListener('popstate', handleRouteChange);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);
  // const handleShopSelect = (selectedOption) => {
  //   setSelectedShop(selectedOption);
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'barCode') {
      const barcodeValue = parseInt(value, 10);
      if (barcodeValue === 100000 || barcodeValue === 500000) {
        toast.error('This barcode value is not available.');
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { other, invoice, defect, identification, ...formDataExt } = formData;
    if (!formData.shop) return toast.error('Fill all fields marked with *');
    // isOpen ? setFormData(formData) : setFormData(initialFormData);
    setSubmitted(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchasing?shop=${formData.shop}`,
        {
          ...formDataExt,
          ...(!defect && defect),
          ...(!identification && identification),
          otherText: formData.other ? formData.otherText : null,
        }
      );
      handleClose();
      toast.success('Purchasing has been made successfully!');
    } catch (error) {
      console.error('Error while sending form data: ', error);
      setSubmitted(false);
      // Check if the error response contains a message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`Error: ${error.response.data.message}`);
        setSubmitted(false);
      } else {
        toast.error('Error while sending purchasing data!');
        setSubmitted(false);
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the Base64 string in formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          identification: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFormData((prevFormData) => ({
      ...prevFormData,
      identification: null,
    }));
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal' style={{ maxWidth: 'unset', width: '80%' }}>
        <div className='Upper-modal-row'>
          <h2>Add New Item</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <label>Date</label>
            <input
              name='date'
              placeholder='Date'
              value={formData.date}
              style={{
                cursor: 'no-drop',
              }}
            />
          </div>
          <div className='input-boxer'>
            <label>Invoice</label>
            <input
              name='invoice'
              placeholder='Invoice'
              value={formData.invoice}
              style={{
                cursor: 'no-drop',
              }}
            />
          </div>
          <div className='input-boxer'>
            <label>*Shop</label>
            <CustomSelect
              options={[{ label: 'None', value: undefined }, ...shopsList]}
              isOpen={customSelectOpen2}
              setIsOpen={setCustomSelectOpen2}
              onClick={closeSelect3}
              clear={clear}
              name='shop'
              placeholder='Shops List'
              icon={true}
              onSelect={handleShopSelect}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <label>*Make</label>
            <input
              type='text'
              name='make'
              placeholder='Make'
              value={formData.make}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>*Model</label>
            <input
              type='text'
              name='model'
              placeholder='Model'
              value={formData.model}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>*IMEI</label>
            <input
              type='number'
              name='imei'
              placeholder='IMEI'
              value={formData.imei}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <label>*Customer Name</label>
            <input
              type='text'
              name='customerName'
              placeholder='Customer name'
              value={formData.customerName}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>*Customer Contact</label>
            <input
              type='text'
              name='contact'
              placeholder='Contact'
              value={formData.contact}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>Notable Defects</label>
            <input
              type='text'
              name='defect'
              placeholder='notable defects'
              value={formData.defect}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='input-boxer'>
            <label>*Network</label>
            <input
              type='text'
              name='network'
              placeholder='Network'
              value={formData.network}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>*Price</label>
            <input
              type='text'
              name='price'
              placeholder='Price'
              value={formData.price}
              onChange={handleInputChange}
            />
          </div>
          <div className='input-boxer'>
            <label>*Retailer</label>
            <input
              type='text'
              name='retailer'
              placeholder='retailer'
              value={formData.retailer}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <hr style={{ marginTop: '30px' }} />
        <div className='row' style={{ marginTop: '30px' }}>
          <CheckBox
            text={'Accessories'}
            checked={formData.accessories}
            onChange={(value) =>
              setFormData((st) => ({ ...st, accessories: value }))
            }
          />
          <CheckBox
            text={'Box'}
            checked={formData.box}
            onChange={(value) => setFormData((st) => ({ ...st, box: value }))}
          />
          <CheckBox
            text={'Charger'}
            checked={formData.charger}
            onChange={(value) =>
              setFormData((st) => ({ ...st, charger: value }))
            }
          />
          <CheckBox
            text={'Headphone'}
            checked={formData.headphone}
            onChange={(value) =>
              setFormData((st) => ({ ...st, headphone: value }))
            }
          />
          <div style={{ display: 'flex', gap: '10px', minWidth: '400px' }}>
            <CheckBox
              text={'Other'}
              checked={formData.other}
              onChange={(value) =>
                setFormData((st) => ({ ...st, other: value }))
              }
            />
            <div
              className='input-boxer'
              style={{
                width: '100%',
              }}
            >
              <input
                type='text'
                style={{
                  width: 'auto',
                }}
                name='otherText'
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <button
          className='continue-modal-button'
          onClick={handleSubmit}
          disabled={submitted}
        >
          Save
        </button>
      </div>
    </div>
  ) : null;
}

export default AddSellingItemModal;
