import React, { useState, useEffect } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";

function EditPaidOut({ isOpens, onCloses, paidoutDetails, onUpdate }) {
  // Initialize formData with default values
  const [formData, setFormData] = useState({
    user: "",
    method: "",
    amount: 0,
    reason: "",
  });

  useEffect(() => {
    if (paidoutDetails) {
      setFormData({
        user: paidoutDetails.user,
        method: paidoutDetails.method,
        amount: paidoutDetails.amount,
        reason: paidoutDetails.reason,
      });
    }
  }, [paidoutDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    onUpdate(formData);
  };

  return isOpens ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Edit Paidout</h2>
          <CloseIcon onClick={onCloses} />
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="user"
              placeholder="Enter User Name"
              disabled={true}
              value={formData.user}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="text"
              name="method"
              placeholder="Enter Payment Method"
              value={formData.method}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="amount"
              placeholder="Enter Amount"
              value={formData.amount}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="text"
              placeholder="Enter Reason"
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button className="continue-modal-button" onClick={handleUpdate}>
          <AddStaff />
          Update Details
        </button>
      </div>
    </div>
  ) : null;
}

export default EditPaidOut;
