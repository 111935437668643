import * as React from 'react';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export default function DatePickers({ toDate, setToDate, onClose }) {
  const handleDateChange = (newDate) => {
    // Update the toDate state in the parent component
    setToDate(newDate);
    // Close the date picker after selecting a date
    onClose();
  };

  return (
    <div style={{ position: 'absolute',top:"0",right:"0",zIndex:"111111111111111111",margin:"20px 0px 0px 0px" }}>
      {/* <DemoItem> */}
        <StaticDatePicker onClose={onClose} onAccept={(newDate)=>{setToDate(newDate)}} value={toDate} actionBar={false} onChange={handleDateChange}orientation='portrait' />
      {/* </DemoItem> */}
    </div>
  );
}
