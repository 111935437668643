import React, { useState, useEffect } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import CustomSelect from "../Components/SelectOption/SelectOption";
import axios from "axios";

import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";

function EditStaffModal({ isOpens, onCloses, staffDetails, onUpdate }) {
  // Initialize formData with default values
  const [formData, setFormData] = useState({
    name: "",
    userName: "",
    shop: "",
  });

  useEffect(() => {
    // Update formData with staffDetails when it's available
    if (staffDetails) {
      setFormData({
        name: staffDetails.name,
        userName: staffDetails.userName,
        password: staffDetails.password,
        shop: staffDetails.shop,
      });
    }
  }, [staffDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    onUpdate(formData);
  };
  const [shopsList, setShopsList] = useState([]);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [clear, setClear] = useState(false);
  const [selectedShop, setSelectedShop] = useState();

  useEffect(() => {
    fetchShopsOptions();
  }, []);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error("Error while fetching shop options: ", error);
    }
  };
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    // setPriceSelectOpen(false);
  };
  const handleShopSelect = (selectedOption) => {
    // setSelectedShop(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      shop: selectedOption,
    }));
  };
  return isOpens ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Edit Staff</h2>
          <CloseIcon onClick={onCloses} />
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <CustomSelect
              options={[{ label: "None", value: undefined }, ...shopsList]}
              isOpen={customSelectOpen2}
              setIsOpen={setCustomSelectOpen2}
              onClick={closeSelect3}
              clear={clear}
              name="Shops lists"
              icon={true}
              onSelect={handleShopSelect}
              placeholder="Select Shop"
            />
          </div>
        </div>
        <button className="continue-modal-button" onClick={handleUpdate}>
          <AddStaff />
          Update Details
        </button>
      </div>
    </div>
  ) : null;
}

export default EditStaffModal;
