// src/components/LineChart.js
import React from "react";
import ReactApexChart from "react-apexcharts";
import '../../Styles/Chart/Chart.css'
const LineChart = (props) => {
  const options = {
    chart: {
      id: "line-chart",
    },
    xaxis: {
      categories: props.categories,
    },
    toolbar: {
      show: false, 
    },
    grid: {
      show: false, 
    }, stroke: {
        curve: 'smooth', 
      },
  };

  const series = [
    {
      name: "Sales",
      data: props.data,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default LineChart;
