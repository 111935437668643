import { Box, Stack, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import BoxItem from '../Components/BoxItem/BoxItem';
import DeleteModal from '../Components/DeleteModal';
import EditPaidOut from '../Components/EditPaidOut';
import Modal from '../Components/Modal';
import CustomSelect from '../Components/SelectOption/SelectOption';
import Spinner from '../Components/Spinner';
import { StaffSelect } from '../Components/UserSelect';
import { ReactComponent as CloseIcon } from '../Icons/close.svg';
import { ReactComponent as PaymentIcon } from '../Icons/payment.svg';
import '../Styles/StockReport/Users.css';

function PaidOut() {
  const [paidOutData, setPaidOutData] = useState([]);
  const [totalPaidOuts, setTotalPaidOuts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [deleteModal, setModalDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPaidoutID, setSelectedPaidoutID] = useState();
  const [refresh, setRefresh] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(null);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [editablePaidoutData, setEditablePaidoutData] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [salesStats, setSalesStats] = useState(null);
  const [tableSpinner, setTableSpinner] = useState(true);
  const [selectedShop, setSelectedShop] = useState();
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [showPaidOutDatePicker, setShowPaidOutDatePicker] = useState(false);

  const togglePaidoutDateFilter = () => setShowPaidOutDatePicker((st) => !st);

  const fetchSalesData = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/cart/stat`;
      const response = await axios.get(
        selectedShop ? `${apiUrl}?shop=${selectedShop}` : apiUrl
      );
      setSalesStats(response.data.data);
    } catch (error) {
      console.error('Error while fetching sales data:', error);
    }
  };

  const handleFilterByDate = async (clear = false) => {
    togglePaidoutDateFilter();
    // if (!fromDate && !toDate) return;
    try {
      let apiUrl = clear
        ? `${process.env.REACT_APP_BASE_URL}/api/v1/cart/stat`
        : `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/cart/stat?dateFrom=${fromDate.format(
            'MM-DD-YYYY'
          )}&dateTo=${toDate.format('MM-DD-YYYY')}`;
      const response = await axios.get(apiUrl);
      setSalesStats(response.data.data);
    } catch (error) {
      console.error('Error while fetching sales data:', error);
    }
  };

  const DEBOUNCE_DELAY = 500; // Adjust the delay as needed

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);
  const [showDateDialog, setShowDateDialog] = useState(false);
  const toggleDateDialog = () => setShowDateDialog((st) => !st);
  const handleFromDateSelect = (date) => {
    console.log('date', date);
    // setFromDate(date);
    setShow(false);
  };

  const [filterItem, setFilterItem] = useState({
    minPrice: 0,
    maxPrice: 1000000,
    method: '',
    date: '',
  });

  const itemsPerPage = 10;

  const startingIndex = (currentPage - 1) * itemsPerPage + 1;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchPaidOutDetails();
    fetchStaffMembers();
    fetchSalesData();
  }, [
    currentPage,
    refresh,
    debouncedSearchTerm,
    filterItem,
    date,
    selectedStaffId,
    selectedShop,
  ]);

  const fetchUsername = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/${userId}`
      );
      return response.data.data.userName;
    } catch (error) {
      console.error(
        `Error while fetching username for user ID ${userId}:`,
        error
      );
      return ''; // Return an empty string in case of an error
    }
  };

  const fetchStaffMembers = async () => {
    try {
      const response = await axios.get(
        selectedShop
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember?shop=${selectedShop}`
          : `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember`
      );
      setStaffMembers(response.data.data.staffMembers);
      const staffOptions = response.data.data.staffMembers.map((staff) => ({
        label: staff.name,
        value: staff._id,
      }));
      setStaffMemberss(staffOptions);
    } catch (error) {
      console.error('Error while fetching staff members:', error);
    }
  };

  const findUserIdByUsername = async (username) => {
    try {
      if (staffMembers.length > 0) {
        for (const staffMember of staffMembers) {
          if (staffMember.userName === username) {
            return staffMember._id;
          }
        }
        return null;
      }
    } catch (error) {
      console.error('Error while finding user ID by username:', error);
      return null;
    }
  };

  const fetchPaidOutDetails = async () => {
    try {
      const queryParams = [];

      if (filterItem.maxPrice !== 1000000) {
        queryParams.push(
          `price=${filterItem.minPrice || 0}-${filterItem.maxPrice || 1000000}`
        );
      }
      if (filterItem.method) {
        queryParams.push(`method=${filterItem.method}`);
      }
      if (date) {
        queryParams.push(`date=${date.format('MM-DD-YYYY')}`);
      }
      if (debouncedSearchTerm) {
        queryParams.push(`username=${debouncedSearchTerm}`);
      }
      if (selectedStaffId) {
        queryParams.push(`staff=${selectedStaffId}`);
      }
      if (selectedShop) {
        queryParams.push(`shop=${selectedShop}`);
      }

      // Join the query parameters with '&' to form the complete query string
      const queryString = queryParams.join('&');

      // Construct the API URL with query parameters
      const apiUrl = queryString ? `paidOut?${queryString}` : `paidOut`;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${apiUrl}`
      );

      setPaidOutData(response.data.data.paidOutsList);
      setTotalPaidOuts(response.data.data.count);
      setTimeout(() => {
        setTableSpinner(false);
      }, 2000);
    } catch (error) {
      console.error('Error while fetching paidout details: ', error);
    }
  };

  const deletePaidoutDetails = async (selectedRecordId) => {
    setRefresh(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/paidOut/${selectedRecordId}`
      );
      setRefresh(false);
      setPaidOutData((prevPaidoutData) =>
        prevPaidoutData.filter((item) => item._id !== selectedRecordId)
      );
      setModalDelete(false);
    } catch (error) {
      console.error('Error while deleting Paidout details: ', error);
    }
  };

  const updatePaidout = async (paidoutId, userName, data) => {
    const { amount, method, reason } = data;
    const userId = await findUserIdByUsername(userName);

    const paidOutBody = {
      id: paidoutId,
      user: userId,
      amount: amount,
      method: method,
      reason: reason,
    };

    setRefresh(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/paidOut`,
        paidOutBody
      );
      setRefresh(false); // This can be used to refresh the paidout list.
      closeModalEdit();
    } catch (error) {
      console.error(
        `Error while updating paidout with ID ${paidoutId}:`,
        error
      );
    }
  };

  const options = [
    { value: 'credit', label: 'Credit' },
    { value: 'cash', label: 'Cash' },
    // { value: "option3", label: "Option 3" },
  ];
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModalEdit = (paidoutDetails) => {
    setEditablePaidoutData(paidoutDetails);
    setModalEdit(true);
  };

  const closeModalEdit = () => {
    setModalEdit(false);
  };
  const openModalDelete = (paidoutId) => {
    setModalDelete(true);
    setSelectedPaidoutID(paidoutId);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };
  const handleSelect = (value) => {
    setFilterItem({ ...filterItem, method: value });
  };
  const closeSelect = () => {
    setCustomSelectOpen2(false);
    setShow(false);
  };
  const closeSelect2 = () => {
    setCustomSelectOpen(false);
    setShow(false);
  };
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    setCustomSelectOpen2(false);
  };
  function formatDateString(inputDateString) {
    const originalDate = new Date(inputDateString);

    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      weekday: 'long',
    };

    return new Intl.DateTimeFormat('en-US', options).format(originalDate);
  }
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchShopsOptions();
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };
  const [clear, setClear] = useState(false);
  const clearValues = () => {
    setFilterItem({ minPrice: 0, method: '', date: '' });
    setClear(true);
    setTimeout(() => {
      setClear(false);
    }, 100);
  };
  const [staffMemberss, setStaffMemberss] = useState([]);

  useEffect(() => {
    // Make an API call to fetch staff members
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/staffMember`)
      .then((response) => {
        const staffData = response.data.data.staffMembers;
        const staffOptions = staffData.map((staff) => ({
          label: staff.name,
          value: staff._id,
        }));
        setStaffMemberss(staffOptions);
      })
      .catch((error) => {
        console.error('Error fetching staff members:', error);
      });
  }, []);

  const handleSelects = (selectedValue) => {
    setSelectedStaffId(selectedValue);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  //   const slicedPaidOutData = paidOutData.slice(startIndex, endIndex);
  const [shopsList, setShopsList] = useState([]);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };
  const [shopListOpen, setShopListOpen] = useState();

  const getUserPayments = (paymentMethod) => {
    if (selectedStaffId) {
      return paidOutData
        ?.filter((payment) => payment.method === paymentMethod)
        ?.map((payment) => payment.amount)
        ?.reduce((acc, curr) => acc + curr, 0)
        ?.toString();
    } else return '0';
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='user-container'>
          <div className='row-container' style={{ minWidth: '100%' }}>
            <BoxItem
              text='Paidout Cash'
              number={salesStats?.paidOutStat?.cash || 0}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE5353 100%)'
            />
            <BoxItem
              text='Paidout Credit'
              number={salesStats?.paidOutStat?.credit || 0}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
            <BoxItem
              text='User Credit Payments'
              number={getUserPayments('credit')}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
            <BoxItem
              text='User Cash Payments'
              number={getUserPayments('cash')}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE9653 100%)'
            />
          </div>
          <div className='hsls'>
            <div className='search-row' style={{ maxWidth: '500px' }}>
              <StaffSelect
                options={staffMemberss}
                onSelect={handleSelects}
                name='Select Staff'
              />
            </div>
            <div
              className='selectoptionscontainers'
              style={{
                marginLeft: '10px',
              }}
            >
              <Box
                sx={{
                  '& > button': {
                    padding: '22px 30px',
                    borderRadius: '10px',
                    background: '#006b8c',
                    fontSize: 16,
                    border: 'none',
                    outline: 'none',
                    boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset',
                    color: 'white',
                    width: 'max-content',
                  },
                }}
              >
                <button onClick={togglePaidoutDateFilter}>
                  PaidOut By Date
                </button>
                {showPaidOutDatePicker && (
                  <>
                    <div className='modal-overlay'>
                      <div className='modal'>
                        <div className='Upper-modal-row'>
                          <h2>Paid Out By Date</h2>
                          <CloseIcon onClick={togglePaidoutDateFilter} />
                        </div>
                        <Stack
                          mt={3}
                          spacing={1}
                          direction={'row'}
                          alignItems='center'
                          justifyContent={'center'}
                          width='100%'
                        >
                          <Stack>
                            <Typography variant='body2'>Date From</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                onChange={(newValue) => {
                                  setFromDate(newValue);
                                }}
                                defaultValue={dayjs(
                                  new Date().toISOString().split('T')[0]
                                )}
                                disableFuture
                                views={['year', 'month', 'day']}
                              />
                            </LocalizationProvider>
                          </Stack>
                          <Stack>
                            <Typography variant='body2'>Date To</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                onChange={(newValue) => {
                                  setToDate(newValue);
                                }}
                                defaultValue={dayjs(
                                  new Date().toISOString().split('T')[0]
                                )}
                                disableFuture
                                views={['year', 'month', 'day']}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Stack>
                        <Stack
                          mt={4}
                          direction='row'
                          spacing={'10px'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          sx={{
                            '& button': {
                              display: 'inline-flex',
                              padding: '18px 68px 18px 61px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '12px',
                              borderRadius: '6px',
                              width: 'fit-content',
                              color: 'white',
                              border: 'none',
                              margin: '50px auto 0px auto',
                              outline: 'none',
                            },
                          }}
                        >
                          <button
                            style={{
                              background: '#ff7c5c',
                            }}
                            onClick={() => handleFilterByDate(false)}
                          >
                            Filter By Date
                          </button>
                          <button
                            onClick={() => handleFilterByDate(true)}
                            style={{
                              background: '#ff7c5c',
                            }}
                          >
                            Reset Filter
                          </button>
                        </Stack>
                      </div>
                    </div>
                  </>
                )}
              </Box>

              <CustomSelect
                options={[{ label: 'None', value: undefined }, ...shopsList]}
                isOpen={shopListOpen}
                setIsOpen={setShopListOpen}
                onClick={closeSelect3}
                clear={clear}
                name='Shops lists'
                icon={true}
                onSelect={handleShopSelect}
                placeholder='Select Shop'
              />
              <CustomSelect
                options={options}
                onSelect={handleSelect}
                name='Payment Method'
                icon={<PaymentIcon />}
                clear={clear}
                onClick={closeSelect}
                isOpen={customSelectOpen}
                setIsOpen={setCustomSelectOpen}
              />
              {/* <PriceSelect
                onSelect={(selectedOption) => {
                  setFilterItem({
                    ...filterItem,
                    minPrice: selectedOption[0],
                    maxPrice: selectedOption[1],
                  });
                }}
                onClick={closeSelect2}
                clear={clear}
                name="Amount"
                isOpen={customSelectOpen2}
                setIsOpen={setCustomSelectOpen2}
                icon={<DollarIcon />}
              /> */}
              {filterItem.method || filterItem.date ? (
                <span
                  className='clear'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    clearValues();
                  }}
                >
                  Reset
                </span>
              ) : null}
            </div>
          </div>
          <div className='stock-table'>
            {tableSpinner ? (
              <Spinner />
            ) : (
              <>
                {paidOutData && paidOutData.length > 0 ? (
                  <>
                    {' '}
                    {tableSpinner ? (
                      <Spinner />
                    ) : (
                      <>
                        <table className='blueTable'>
                          <thead>
                            <tr>
                              <th className='short'>#</th>
                              <th>User Name</th>
                              <th>Amount</th>
                              <th>Payment Method</th>
                              <th>Reason</th>
                              <th>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paidOutData.map((item, index) => (
                              <tr key={item._id}>
                                <td>{index + startingIndex}</td>
                                <td>{item.user?.userName}</td>
                                <td>{item.amount}</td>
                                <td>{item.method}</td>
                                <td>{item.reason}</td>
                                <td>
                                  {new Date(
                                    item.createdAt ?? item.updatedAt
                                  ).toLocaleString() || 'None'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <table className='blueTable'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User Name</th>
                          <th>Amount</th>
                          <th>Payment Method</th>
                          <th>Reason</th>
                          <th className='long'>Actions</th>
                        </tr>
                      </thead>
                    </table>
                    <div className='no-data'>
                      <p>No data available</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <Modal isOpen={isModalOpen} onClose={closeModal} />
          <EditPaidOut
            isOpens={modalEdit}
            onCloses={closeModalEdit}
            paidoutDetails={editablePaidoutData}
            onUpdate={(data) =>
              updatePaidout(
                editablePaidoutData._id,
                editablePaidoutData.user,
                data
              )
            }
          />
          <DeleteModal
            isDelete={deleteModal}
            ondeleteClose={closeModalDelete}
            selectedRecordId={selectedPaidoutID}
            deleteRecord={deletePaidoutDetails}
          />
        </div>
      )}
    </>
  );
}

export default PaidOut;
