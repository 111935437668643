import React, { useState, useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";
import Pin from "./Pin";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logoutUser } from "../Redux-store/userActions";

function PasswordChangeModal({closeModale}) {
  const [showPin, setShowPin] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const updatePassword = async (id, password,reEnterPassword) => {
    console.log(id, password, reEnterPassword);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/resetPassword`,
        {
          id,
          password: password,
          reEnterPassword:reEnterPassword
        }
      );
      console.log("Password updated successfully:", response.data);
      setFormData({password:"",reEnterPassword:""})
      closeModale()
      setModalOpen(false)
      toast.success("Password updated successfully")
      dispatch(logoutUser());
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate("/login");
      return response.data; // You can return the response data if needed
    } catch (error) {
      console.error("Error updating password:", error);
      setFormData({password:"",reEnterPassword:""})
      toast.error("Error While upating password")
     // throw error; // You can rethrow the error if needed
    }
  };
  const initialFormData = {
    password: "",
    reEnterPassword: "", // Add confirmPassword field
  };
  // Initialize formData with default values
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // If passwords match, proceed to add the staff member
    setShowPin(true);
    const user = JSON.parse(localStorage.getItem("user"));
    updatePassword(user._id, formData.password, formData.reEnterPassword);
  };
  const closeModal = () => {
    closeModale()
    setModalOpen(false);
  };
  return  (
    <>
      {modalOpen && ( // Render modal if modalOpen is true
        <div className="modal-overlay">
          <div className="modal">
            <div className="Upper-modal-row">
              <h2>Reset Password</h2>
              <CloseIcon onClick={closeModal} />
            </div>
            <div className="row" style={{ flexDirection: "column", gap: "20px" }}>
              <div className="input-boxer">
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-boxer">
                <input
                  type="password"
                  name="reEnterPassword"
                  placeholder="Re Enter Password"
                  value={formData.reEnterPassword}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button className="continue-modal-button" onClick={handleSubmit}>
              <AddStaff />
              Save Password
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PasswordChangeModal;
