import React, { useEffect, useState } from 'react';
// import BuyerHeader from "../Components/Header/BuyerHeader";
import '../Styles/Selling/Selling.css';
// import ListingBox from "./ListingBox";
import axios from 'axios';
import { debounce } from 'lodash';
// import AddSellingItemModal from '../Components/AddSellingItemModal';
import EditSellingModal from '../Components/EditSellingModal';
// import SearchInput from '../Components/Search';
import AddSellingItemModal from '../Components/AddSellingItemModal';
import SearchInput from '../Components/Search';
import CustomSelect from '../Components/SelectOption/SelectOption';
import SellingDetailModal from '../Components/SellingDetails';
import Spinner from '../Components/Spinner';
import { ReactComponent as AdditonIcon } from '../Icons/addition.svg';

function Selling() {
  const [isLoading, setIsLoading] = useState(true);
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [purchasingData, setPurchasingData] = useState([]);
  const [editSellingPriceDetails, setEditSellingPriceDetails] = useState();
  const [selectedShop, setSelectedShop] = useState();
  const [searchItem, setSearchItem] = useState({
    make: '',
    network: '',
    model: '',
    imei: '',
    shop: '',
    customerName: '',
  });
  const openModal = (item) => {
    setIsModalOpen(true);
    setEditSellingPriceDetails(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const debouncedFetchPurchasedData = debounce(() => {
    fetchPurchasingData();
  }, 500);

  const handleSearch = () => {
    debouncedFetchPurchasedData();
  };

  useEffect(() => {
    handleSearch();
  }, [searchItem]);

  const fetchPurchasingData = async () => {
    // Construct the query parameters based on selected search parameters
    const queryParams = [];
    for (const key in searchItem) {
      if (searchItem[key]) {
        queryParams.push(`${key}=${searchItem[key]}`);
      }
    }

    // Join query parameters with '&'
    const queryString = queryParams.join('&');

    const apiUrl = `/api/v1/purchasing?pageNumber=1&recordsPerPage=20000${
      queryString && `&${queryString}`
    }`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${apiUrl}`
      );
      setPurchasingData(response.data.data);
      // setRefresh(false);
    } catch (error) {
      console.error('Error fetching purchasing data:', error);
    }
  };

  useEffect(() => {
    fetchPurchasingData();
  }, [refresh, selectedShop]);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [tableSpinner, setTableSpinner] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    fetchShopsOptions();
    setTimeout(() => {
      setTableSpinner(false);
    }, 4000);
  }, []);
  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    setCustomSelectOpen2(false);
  };

  const [clear, setClear] = useState(false);
  const [shopsList, setShopsList] = useState([]);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };
  const [shopListOpen, setShopListOpen] = useState();
  const [details, setDetails] = useState(null);

  const [addModal, setAddModal] = useState(false);
  const toggleAddModal = () => setAddModal((st) => !st);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='selling-container'>
          <div className='selling-row '>
            <div className='button-box' onClick={toggleAddModal}>
              <div className='icon'>
                <AdditonIcon />
              </div>
              <h3>Add New Item</h3>
            </div>
          </div>
          <div className='selling-row '>
            <SearchInput
              placeholder='Customer Name'
              searchTerm={searchItem.customerName}
              onChange={(e) =>
                setSearchItem({ ...searchItem, customerName: e.target.value })
              }
            />
            <SearchInput
              placeholder='Manufactured By'
              searchTerm={searchItem.make}
              onChange={(e) =>
                setSearchItem({ ...searchItem, make: e.target.value })
              }
            />
            <SearchInput
              placeholder='Model Name'
              searchTerm={searchItem.model}
              onChange={(e) =>
                setSearchItem({ ...searchItem, model: e.target.value })
              }
            />
            <SearchInput
              placeholder='IMEI'
              searchTerm={searchItem.imei}
              onChange={(e) =>
                setSearchItem({ ...searchItem, imei: e.target.value })
              }
            />
            {/* <div style={{ minWidth: '200px' }}> */}
            <CustomSelect
              options={[{ label: 'None', value: undefined }, ...shopsList]}
              isOpen={shopListOpen}
              setIsOpen={setShopListOpen}
              onClick={closeSelect3}
              clear={clear}
              name='Shops lists'
              icon={true}
              onSelect={(e) => setSearchItem({ ...searchItem, shop: e })}
              placeholder='Select Shop'
            />
            {/* </div> */}
            {/* <button className='search-button' onClick={handleSearch}>
              Search
            </button> */}
          </div>
          <div className='selling-listing-table'>
            {purchasingData && purchasingData.length > 0 ? (
              <>
                {tableSpinner ? (
                  <Spinner />
                ) : (
                  <table className='blueTable' style={{ maxWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>Invoice</th>
                        <th>Purchasing Price</th>
                        <th>Selling Price</th>
                        <th>Manufacturer</th>
                        <th>Model</th>
                        <th>IMEI</th>
                        <th>Customer Name</th>
                        <th>Customer Contact</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchasingData.map((item) => (
                        <tr key={item._id}>
                          <td className='shrink'>{item.invoice}</td>
                          <td className='shrink'>£{item.price}</td>
                          <td className='shrink'> £{item.sellingPrice}</td>
                          <td className='shrink'>{item.make}</td>
                          <td className='shrink'>{item.model}</td>
                          <td className='color shrink'>{item.imei}</td>
                          <td className='shrink'>{item.customerName}</td>
                          <td className='shrink'>{item.contact}</td>
                          <td className='shrink'>
                            {!item.stock ? 'Sold Out' : 'In Stock'}
                          </td>
                          <td
                            style={{
                              display: 'flex',
                              alignItems: 'stretch',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              className='submit'
                              onClick={() => {
                                openModal(item);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              style={{ margin: '0% 2%' }}
                              className='submit'
                              onClick={() => setDetails(item)}
                            >
                              Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            ) : (
              <>
                <table className='blueTable'>
                  <thead>
                    <tr>
                      <th>Barcode</th>
                      <th>Purchase Date</th>
                      <th>Purchasing Price</th>
                      <th>Selling Price</th>
                      <th>Manufacturer</th>
                      <th>Model</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {details && (
        <SellingDetailModal
          sellingData={details}
          close={() => setDetails(null)}
        />
      )}
      <EditSellingModal
        isOpen={isModalOpen}
        onClose={closeModal}
        fetchPurchasingData={fetchPurchasingData}
        editSellingPriceDetails={editSellingPriceDetails}
      />
      <AddSellingItemModal
        isOpen={addModal}
        onClose={toggleAddModal}
        addStockItem={(data) => {}}
      />
    </>
  );
}

export default Selling;
