import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Navbar from '../Components/NavBar/Navbar';
import { ReactComponent as DateIcon } from '../Icons/date.svg';
import '../Styles/Home/Home.css';
import Home from './Home';
import Shops from './Shops';

import { useSelector } from 'react-redux';
import DatePickers from '../Components/DatePicker/Dates';
import ProtectedRoute from '../Components/ProtectedRoute';
import Accessories from './Accessories';
import PaidOut from './PaidOut';
import RepairListing from './RepairListing';
import RepairSheetDetail from './RepairSheetDetail';
import Selling from './Selling';
import Settings from './Settings';
import Stock from './Stock';
import Unlocking from './Unlocking';
import Users from './Users';
function HomePage() {
  const [current, setCurrent] = useState(null);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(null);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    console.log(date);
  }, [date]);

  function formatDateString(inputDateString) {
    const originalDate = new Date(inputDateString);

    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      weekday: 'long',
    };

    return new Intl.DateTimeFormat('en-US', options).format(originalDate);
  }
  function getCurrentDate() {
    const currentDate = new Date();

    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      weekday: 'long',
    };

    return new Intl.DateTimeFormat('en-US', options).format(currentDate);
  }
  useEffect(() => {
    setCurrent(getCurrentDate());
  }, []);

  return (
    <>
      <div className='admin-homepage'>
        <Navbar />
        <div className='container-homepage'>
          <div className='lower'>
            <h3>Welcome To Admin Dashboard</h3>
            <p
              className='time'
              onClick={() => {
                setShow(!show);
              }}
              style={{ position: 'relative' }}
            >
              <span>
                <DateIcon />
              </span>
              {date == null ? current : formatDateString(date.$d)}
              {show && (
                <DatePickers
                  toDate={date}
                  onClose={() => {
                    setShow(!show);
                  }}
                  setToDate={setDate}
                />
              )}
            </p>
          </div>
          <div>
            <Routes>
              <Route
                path='/home'
                element={token ? <Home /> : <Navigate to='/login' replace />}
              />
              <Route
                path='/users'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/shop'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Shops />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/stocks'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Stock />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/settings'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Settings />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/repairlisting'
                element={
                  token ? (
                    <ProtectedRoute>
                      <RepairListing />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/unlocking'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Unlocking />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/selling'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Selling />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/accessories'
                element={
                  token ? (
                    <ProtectedRoute>
                      <Accessories />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/paidout'
                element={
                  token ? (
                    <ProtectedRoute>
                      <PaidOut />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route
                path='/buyer/repair-sheet/detail'
                element={
                  token ? (
                    <ProtectedRoute>
                      <RepairSheetDetail />
                    </ProtectedRoute>
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
//
export default HomePage;
